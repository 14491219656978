import {CLEAR_ORDER, FETCH_ORDER_VN_ONE, LIST_ORDER_VN_ONE} from "./types";

const init = {
    isLoading: false,
    data: {
        tickets: [],
        drawShifts: [],
        summary: {
            grandTotalUsd: {
                betAmount: 0,
                waterAmount: 0,
                winAmount: 0,
                winLoseAmount: 0
            },
            grandTotalKhr: {
                betAmount: 0,
                waterAmount: 0,
                winAmount: 0,
                winLoseAmount: 0
            }
        },
    },
    filter: {},
    page: 1,
    totals: 0,
    size: 25
};
export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_ORDER_VN_ONE:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_ORDER_VN_ONE:
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        case CLEAR_ORDER:
            return {
                ...init
            };
        default:
            return state;
    }
}